




































































import { Component, Vue } from 'vue-property-decorator'
import { App3rdParty } from '@/models/App3rdParty'
import {
	getAvailableApps,
	add3rdPartyApp,
	delete3rdPartyApp,
} from '@/clients/cpinblocks'
import { KeycloakTokenParsed, KeycloakOnLoad } from 'keycloak-js'
import { env } from '@/utils'

@Component({
	components: {},
})
export default class Apps extends Vue {
	public loading = true;
	public externalApps: App3rdParty[] = [];
	public cardToShowIdpAlias = '';

	private async mounted (): Promise<void> {
		this.externalApps = await getAvailableApps()

		for (let i = 0; i < this.externalApps.length; i++) {
			if (this.$route.hash.indexOf(this.externalApps[i].idpAlias) > -1) {
				this.cardToShowIdpAlias = this.externalApps[i].idpAlias
				break
			}
		}

		if (this.cardToShowIdpAlias) {
			this.externalApps = this.externalApps.map((app) => {
				return { ...app, hidden: app.idpAlias === this.cardToShowIdpAlias }
			})
		}

		this.loading = false
	}

	get tokenParsed (): KeycloakTokenParsed | null {
		if (this.$keycloak.tokenParsed) return this.$keycloak.tokenParsed

		return null
	}

	public setImage (app: App3rdParty): string {
		const images = require.context('@/assets/apps', false, /\.svg$/)

		return images('./' + app.label?.toLowerCase() + '.svg')
	}

	public isAppAlreadyConnected (app: App3rdParty): boolean {
		const jwtClaim = app.idpAlias

		// Check if your JWT token already contains an app
		if (
			this.tokenParsed &&
			jwtClaim &&
			Object.prototype.hasOwnProperty.call(
				this.tokenParsed.identity_providers,
				jwtClaim
			)
		) {
			return true
		}

		return false
	}

	update (): void {
		const initOptions = {
			url: env('VUE_APP_IBEX_AUTH_ENDPOINT'),
			realm: 'inblocks-ibex',
			clientId: 'ibex-ui',
			onLoad: 'login-required' as KeycloakOnLoad,
		}

		this.$keycloak
			.init({ onLoad: initOptions.onLoad })
			.then(auth => {
				if (!auth) {
					this.$store.commit('jwt', null)
					window.location.reload()
				} else {
					this.$store.commit('jwt', this.$keycloak.token)
				}
			})
			.catch(() => {
				// If the user decline Terms and Conditions
				window.location.reload()
			})
	}

	public onAddClick (app: App3rdParty): void {
		const sessionState = this.tokenParsed?.session_state
		const clientID = this.tokenParsed?.azp
		const redirectLink = `${window.location.origin}/apps`

		add3rdPartyApp(app, redirectLink, clientID, sessionState)
	}

	public async onDeleteClick (app: App3rdParty): Promise<void> {
		this.loading = true

		await delete3rdPartyApp(this.$store.state.jwt, app)
		this.update()

		this.loading = false
	}

	public appUserID (app: App3rdParty): string {
		if (app.idpAlias && this.tokenParsed) { return this.tokenParsed?.identity_providers[app.idpAlias].username }

		return ''
	}
}
